'use strict';

intellidealerAppCoreModule.controller('LoginController', function ($scope, $state, $location, $anchorScroll, $timeout, $filter, $rootScope, loginResource,
                                                                   userConfigurationService, securityResource, alertService, globalAlertService,
                                                                   logoutResource, $stateParams, dealershipConfiguration, loginRedirectService,
                                                                   rememberMeService, version, customizedMessagesService,
                                                                   shoppingCartService, locationService, publicModeService, cdkAnalyticsService,
                                                                   contactsResource, loginService, multiMediaStreamingURLService) {

  sessionStorage.clear();

  $scope.browser = {
    isIE: bowser.msie,
    isSupported: bowser.chrome || bowser.firefox || bowser.msedge || bowser.safari
  };

  $scope.supportContactMessage = $filter('languageHyperlink')('mydealer.ui.unsupportedBrowser.havingTrouble',
    ['mydealer.ui.unsupportedBrowser.havingTrouble.systemAdministrator'],
    [dealershipConfiguration && dealershipConfiguration.systemAdminEmail ? 'mailto:' + dealershipConfiguration.systemAdminEmail : '']);

  $scope.user = {username: '', password: '', rememberMe: false};
  $scope.alerts = globalAlertService.currentAlerts;
  $scope.logo = multiMediaStreamingURLService.getMultiMediaStreamingPublicWebConfigURL(dealershipConfiguration.remoteServerPath + dealershipConfiguration.dealershipLogo, true);
  $scope.myDealerBackground = multiMediaStreamingURLService.getMultiMediaStreamingPublicWebConfigURL(dealershipConfiguration.myDealerLogonImage && (dealershipConfiguration.remoteServerPath + dealershipConfiguration.myDealerLogonImage), false);
  $scope.dealerType = dealershipConfiguration.dealerType.symbol;
  $scope.version = version.ppuVersion? `${version.ppuVersion.slice(0,4)}.${version.ppuVersion.slice(4,6)}.${version.ppuVersion.slice(-3)}` : '0.0.0';
  $scope.forgotForm = {};
  $scope.customizedMessage = null;
  // load the username and password if remembered
  var loginData = rememberMeService.getLogin($stateParams.library ? $stateParams.library.toUpperCase() : $stateParams.library);
  if (loginData) {
    $scope.user.username = loginData.username;
    $scope.user.password = loginData.password;
    $scope.user.rememberMe = true;
  }

  // if forced to the login from an expired session, show an error indicating it
  if ($stateParams.redirectTo && $stateParams.redirectTo.length > 0) {
    globalAlertService.clearErrors();
    globalAlertService.addError('mydealer.ui.login.session_has_expired');
  }

  $scope.forgotPasswordClicked = function () {
    if (!$scope.forgotForm.username) {
      $scope.forgotForm.username = $scope.user.username;
    }
  };

  var login = function () {
    securityResource.customerContactSecurity.get(function (data) {
      userConfigurationService.setFlags(data);
      if (data.contactId) {
        cdkAnalyticsService.setGlobalField('company', data.contactId.company);
        cdkAnalyticsService.setGlobalField('division', data.contactId.division);
        cdkAnalyticsService.setGlobalField('customerId', data.contactId.customerNumber);
        $rootScope.gadimension2 = data.contactId.customerNumber;
        $rootScope.gadimension3 = data.contactId.company;
        $rootScope.gadimension4 = data.contactId.division;
      }
      $rootScope.gadimension2 = $scope.user.username;
      cdkAnalyticsService.logLogin($scope.user.username);

      // save or clear the username and password for remember me
      if ($scope.user.rememberMe) {
        rememberMeService.setLogin($stateParams.library.toUpperCase(), $scope.user.username, $scope.user.password);
      } else {
        rememberMeService.clearLogin($stateParams.library ? $stateParams.library.toUpperCase() : $stateParams.library);
      }

      if ($stateParams.redirectTo) {
        loginRedirectService.continueLogin($stateParams.redirectTo);
      } else {
        $state.go('navigation.dashboard', {library: $stateParams.library ? $stateParams.library.toUpperCase() : $stateParams.library});
      }
    }, function (response) {
      globalAlertService.handleErrorResponse(response);
      logoutResource.get(function () {
        $rootScope.gadimension2 = $scope.user.username;
        cdkAnalyticsService.logLogout($scope.user.username);
      }, function () {
        alertService.addAlert(
          globalAlertService.currentAlerts,
          {
            message: 'security_failed', //User security failed, but session may still be active!
            type: 'warning'
          }
        );
      });
    });
  };

  $scope.submit = function (event) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
    locationService.clearDefaultLocation();
    publicModeService.clearCartReferenceNumber();
    globalAlertService.clearErrors();
    shoppingCartService.clearNotFoundItems();
    if (!$scope.user.username || !$scope.user.password) {
      globalAlertService.addError('mydealer.ui.login.username_and_password_invalid');
    } else {
      loginResource.post(
        {
          username: $scope.user.username,
          password: $scope.user.password,
          library: $stateParams.library ? $stateParams.library.toUpperCase() : $stateParams.library,
          system: 'MD'
        },
        function () {
          loginService.populateAssignedContacts(login);
        },
        function (response) {
          if (response.status === 401) {
            if(response.data.message == 'Feature not licensed.'){
              globalAlertService.addError('mydealer.ui.login.license_expired');
            }
            else{
              globalAlertService.addError('mydealer.ui.login.username_and_password_invalid');
            }

          } else if(response.data && response.data.type === "AUTHORIZATION") {
            $scope.user.password = "";
            globalAlertService.handleErrorResponse(response);
          } else {
            globalAlertService.handleErrorResponse(response);
          }
        }
      );
    }
  };

  $scope.forgotPassword = function (event) {
    if (event) {
      event.preventDefault(); // prevent default form submit (IE10)
    }
    $rootScope.togglePopover('forgotPasswordLink');

    securityResource.generateMyDealerForgotPasswordEmail.save(
      {
        hostUrl: $location.absUrl().split('#')[0] + '#/forgot-password?',
        library: $stateParams.library ? $stateParams.library.toUpperCase() : $stateParams.library,
        contactUser: $scope.forgotForm.username,
        contactEmail: $scope.forgotForm.emailAddress
      },
      function () {
        globalAlertService.addSuccess('mydealer.ui.login.password_emailed');
      },
      globalAlertService.handleErrorResponse
    );
  };

  // set the customized message if one is configured
  function loadCustomizedMessage() {
    $scope.customizedMessage = null;
    angular.forEach(customizedMessagesService.getMessages(), function (value) {
      if (value.referenceNumber === 'LOGIN' && !value.location) {
        $scope.customizedMessage = value.description;
      }
    });
  }

  loadCustomizedMessage();

});
