'use strict';

intellidealerAppCoreModule.service('multiMediaStreamingURLService', function ($stateParams,appConfigurationService) {

  const multimediaPublicBasePath  = '/rest/public/multimedia/getMultiMediaStream';
  const multimediaBasePath = '/rest/multimedia/getMultiMediaStream';

  this.getEquipmentMultimediaParams = function (equipment, pictureLocation) {
    var multimediaPath = pictureLocation && pictureLocation.split('/equipment/')[1];
    return '&company=' + equipment.equipmentId.company +
      '&division=' + equipment.equipmentId.division +
      '&location=' + '' +
      '&multimediaPath=' + encodeURIComponent(multimediaPath) +
      '&keyToMultimedia=' + equipment.equipmentId.stockNumber +
      '&moduleDesignation=' + 'EQUIPMENT_PROFILE'  +
      '&library=' + $stateParams.library +
      '&fileType=' + 'MULTIMEDIA';
  };

  this.getEquipmentSalesMultimediaParams = function (equipmentSaleDetails, pictureLocation) {
    var multimediaPath = pictureLocation && pictureLocation.split('/eSales/')[1];
    return '&company=' + equipmentSaleDetails.equipmentSaleId.company +
      '&division=' + equipmentSaleDetails.equipmentSaleId.division +
      '&location=' + '' +
      '&multimediaPath=' + encodeURIComponent(multimediaPath) +
      '&keyToMultimedia=' + equipmentSaleDetails.equipmentSaleId.stockNumber +
      '&moduleDesignation=' + 'EQUIPMENT_PROFILE'  +
      '&library=' + $stateParams.library +
      '&fileType=' + 'EQUIPMENT_SALE' ;
  };

  this.getMyRentalMultimediaParams = function (myRentalDetails, pictureLocation) {
    var multimediaPath = pictureLocation && pictureLocation.split('/equipment/')[1];
    return '&company=' + myRentalDetails.id.company +
        '&division=' + myRentalDetails.id.division +
        '&location=' + '' +
        '&multimediaPath=' + encodeURIComponent(multimediaPath) +
        '&keyToMultimedia=' + myRentalDetails.combinedStockPartNumber +
        '&moduleDesignation=' + 'EQUIPMENT_PROFILE'  +
        '&library=' + $stateParams.library +
        '&fileType=' + 'MULTIMEDIA';
  };

  this.getPartsParams = function (multiMediaId) {
    return '&company=' + multiMediaId.company +
      '&division=' + multiMediaId.division +
      '&location=' + multiMediaId.location +
      '&multimediaPath=' + encodeURIComponent(multiMediaId.multimediaPath) +
      '&keyToMultimedia=' + multiMediaId.keyToMultimedia +
      '&moduleDesignation=' + multiMediaId.moduleDesignation  +
      '&library=' + $stateParams.library +
      '&fileType=' + 'MULTIMEDIA';
  };

  this.webConfigParams = function (isLogo){
    return '&fileType=WEB_CONFIG' +
    '&imageType=' + (isLogo ? 'LOGO' : 'BACKGROUNDIMAGE') +
    '&library=' + $stateParams.library;
  };

  this.getMultiMediaStreamingURL = function (pictureLocation, params) {
    return `${appConfigurationService.basePath}${multimediaBasePath}?pictureLocation=${encodeURIComponent(pictureLocation)}${params}`;
  };

  this.getMultiMediaStreamingPublicURL = function (pictureLocation, params) {
    return `${appConfigurationService.basePath}${multimediaPublicBasePath}?pictureLocation=${encodeURIComponent(pictureLocation)}${params}`;
  };

  this.getMultiMediaStreamingPublicWebConfigURL = function (pictureLocation, isLogo) {
    var params = '&fileType=WEB_CONFIG' +
      '&imageType=' + (isLogo ? 'LOGO' : 'BACKGROUNDIMAGE') +
      '&library=' + $stateParams.library;
    return `${appConfigurationService.basePath}${multimediaPublicBasePath}?pictureLocation=${encodeURIComponent(pictureLocation)}${params}`;
  };

  this.getMultiMediaStreamingWebConfigURL = function (pictureLocation, isLogo) {
    var params = this.webConfigParams(isLogo);
    return `${appConfigurationService.basePath}${multimediaBasePath}?pictureLocation=${encodeURIComponent(pictureLocation)}${params}`;
  };

  this.getMultiMediaStreamingDealerSettingURL = function (serverPath, myDealerBranchSettings, isLogo) {
    var imagePath = isLogo? myDealerBranchSettings.myDealerBranchLogoImg : myDealerBranchSettings.myDealerHomePageImg;

    var params = '&fileType=DEALER_SETTING' +
      '&company=' + myDealerBranchSettings.company +
      '&division=' + myDealerBranchSettings.division +
      '&location=' + myDealerBranchSettings.location +
      '&multimediaPath=' + encodeURIComponent(imagePath) +
      '&imageType=' + (isLogo ? 'LOGO' : 'BACKGROUNDIMAGE');

    return `${appConfigurationService.basePath}${multimediaBasePath}?pictureLocation=${encodeURIComponent(serverPath + imagePath.trim())}${params}`;
  };

});
